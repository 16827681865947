// Custom Post Styling
.lead-scripture{
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 1.75rem;
    .scripture::before, .scripture::after{
        content: "'";
    }
    .reference{
        font-size: 1.5rem;
        font-weight: 400;
    }
    .reference::before{
        content:"  -  "
    }
}

blockquote{
    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid #212529;
    color: #212529;
}

.social-share{
    background: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 20px 0px;
    z-index: 999;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 75%;

    @media screen and (max-width: 767px){
        .no-phone{
            display: none;
        }
    }

    .share-btn-twitter,.share-btn-facebook,.share-btn-rss{
        border: 1px solid #f1f1f1;
        border-radius: 5px;
        padding: 7px 10px;
        color: #fff;
        margin-right: 20px;
    }

    .share-btn-twitter{
        background: rgb(29, 161, 242);

        &:hover{
            background: rgb(26, 145, 218);
            text-decoration: none;
        }
    }

    .share-btn-facebook{
        background: rgb(24, 119, 242);

        &:hover{
            background: rgb(14, 82, 176);
            text-decoration: none;
        }
    }
    .share-btn-rss{
        background: rgb(255,165,0);

        &:hover{
            background: rgb(242,140,55);
            text-decoration: none;
        }
    }
}

.suggested-reading{
    display: grid;

    @media screen and (min-width: 1024px){
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }

    figure{
        text-align: center;
        background: #f1f1f1;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 20px;

        figcaption{
            margin-top: 20px;
        }

        img{
            max-width: 100%;
        }
    }
}

.comments{
    h2,h3,h4{
        color: #6c757d;
    }

    ::-webkit-input-placeholder{
        font-size: 65%;
    }

    ::-moz-placeholder{
        font-size: 65%;
    }

    :-ms-input-placeholder{
        font-size: 65%;
    }

    ::-ms-input-placeholder{
        font-size: 65%;
    }

    ::placeholder{
        font-size: 65%;
    }
}

/* The Modal (background) */
.cmodal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 999999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0) !important; /* Fallback color */
    background-color: rgba(0,0,0,0.7) !important; /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #fefefe;
    margin: 5% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 70%; /* Could be more or less, depending on screen size */
  }

  .cmodal-content{
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 30px;

    .review{
        font-style: italic;
    }

    a{
        font-weight: bold;
    }

    .buy-links{
        display: flex;
        gap: 15px;

        a{
            box-shadow: 0px 0px 1px rgba(0,0,0,0.7);
        }
        
        a:hover{
            box-shadow: 5px 5px 10px rgba(0,0,0,0.7);
        }

        img{
            max-width: 100%;
            
        }
    }
  }

  @media screen and (max-width: 960px) {
    .cmodal-content{
        display: flex;
        flex-direction: column-reverse;

        .buy-links{
            flex-direction: column;
        }
    

    }
    
  }
  
  /* The Close Button */
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  